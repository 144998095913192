<template>
  <div
    class="work-card d-flex flex-column justify-content-center align-items-center"
  >
    <div class="work-card__icon">
      <img :src="item.icon" class="" />
    </div>
    <p class="work-card__title mb-0 mt-3">{{ item?.step }}</p>
    <p class="work-card__description mt-2">{{ item?.description }}</p>
  </div>
</template>

<script>
export default {
  name: "WorkCard",
  props: {
    item: Object,
  },
};
</script>

<style lang="scss" scoped>
.work-card {
  padding: 30px 20px;
  background: #ffffff;
  margin-top: 80px;
  min-height: 199px;

  &__icon {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    padding: 10px;
    background: #ffcc01;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -80px;
    img {
      width: 70% !important;
    }
  }

  &__title {
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 24px;
    color: #1d1729;
    font-weight: 800;

    text-align: center;
  }
  &__description {
    font-size: 15px;
    // line-height: 24px;
    line-height: 20px;
    color: #6c6a72;
    font-weight: 400;

    text-align: center;
  }

  @media (min-width: 768px) and (max-width: 1199.99px) {
    min-height: 219px !important;
    &__icon {
      margin-top: -95px;
    }
  }
}
</style>
