<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <!-- First section with 70% width -->
        <img
          src="@/assets/imgs/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">Bent u eigenaar van de woning?</p>
        <!-- v-model="selected" -->

        <div class="row no-gutters">
          <div class="col-lg-10 col-12">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                :aria-describedby="ariaDescribedby"
                v-model="selected"
                name="radio-sub-component"
                class="align-items-center"
              >
                <div class="check-item">
                  <b-form-radio @click="handleClick('4680')" value="4680"
                    >Ja, ik ben eigenaar van de woning
                  </b-form-radio>
                </div>

                <div class="check-item mt-3">
                  <b-form-radio value="4683" @click="handleClick('4683')"
                    >Nee, ik ben geen eigenaar van de woning</b-form-radio
                  >
                </div>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../../GoogleMapSection.vue";

export default {
  name: "SecondQuestion",
  data() {
    return {
      selected: "",
    };
  },
  components: {
    GoogleMapSection,
  },
  props: {
    question: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleClick(val) {
      if (val == "4680") {
        this.$emit("nextQuestion", "3", val);
      } else {
        this.$emit("nextQuestion", "2b", val);
      }
    },

    goToPreviousPage() {
      this.$emit("previousQuestion", "1");
    },
  },
  watch: {
    selected() {
      if (this.selected == "4680") {
        this.$emit("nextQuestion", "3", this.selected);
      } else {
        this.$emit("nextQuestion", "2B", this.selected);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    // background-image: url(../../assets/imgs/secondQuestion.png);
    // background-size: 100% 100% !important;
  }
}
</style>
