<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <img
          src="@/assets/imgs/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">
          <!-- Heb je een plat of een schuin dak? -->
          Wat wil je isoleren?
        </p>

        <div class="row no-gutters">
          <div class="col-lg-10 col-12">
            <b-form-group v-slot="{ ariaDescribedby }">
              <!-- <b-form-checkbox-group
                id="radio-group-2"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
              > -->
              <div class="check-item mt-3">
                <b-form-checkbox
                  :checked="isChecked('4A')"
                  @change="addOption('4A')"
                  >Spouwmuur isoleren (in de muur)</b-form-checkbox
                >
              </div>
              <div class="check-item mt-3">
                <b-form-checkbox
                  :checked="isChecked('4B')"
                  @change="addOption('4B')"
                  >Buitengevel isoleren (buitenkant van de
                  muur)</b-form-checkbox
                >
              </div>
              <div class="check-item mt-3">
                <b-form-checkbox
                  :checked="isChecked('4C')"
                  @change="addOption('4C')"
                  >Dak isoleren</b-form-checkbox
                >
              </div>
              <div class="check-item mt-3">
                <b-form-checkbox
                  :checked="isChecked('4D')"
                  @change="addOption('4D')"
                  >Vloer isoleren</b-form-checkbox
                >
              </div>
              <!-- </b-form-checkbox-group> -->
            </b-form-group>
            <b-button
              class="mt-3 w-100"
              :disabled="!this.ans.length"
              @click="handleClick"
            >
              Volgende
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";
import { BModal, BButton } from "bootstrap-vue";

export default {
  name: "Fourth_Question",

  data() {
    return {
      selected: "",
      ans: [],
    };
  },
  components: {
    GoogleMapSection,
  },
  props: {
    question: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    addOption(option) {
      if (this.ans.includes(option)) {
        this.ans = this.ans.filter((ans) => ans !== option);
      } else {
        this.ans.push(option);
      }
    },

    handleClick() {
      this.$emit("nextQuestion", "followUpQuestion", this.ans);
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "3");
    },

    isChecked(option) {
      return this.ans.includes(option);
    },
  },

  watch: {
    selected() {
      this.$emit("nextQuestion", "5", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    background-image: url(../../../assets/imgs/secondQuestion.png);
    background-size: 100% 100% !important;
  }
}
</style>
